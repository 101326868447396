/**
 * Read device sensor data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';
import CheckAccess from '../CheckAccess';

/**
 *
 * @param { object} db
 * @param { device: device, startTime: epoch, endTime: epoch } dataObj
 * @returns
 */
export default async function ReadDayLogDocs(db, dataObj) {
  //console.log(dataObj);

  try {
    if (
      dataObj.device.data === undefined ||
      dataObj.device.data.owner === undefined
    ) {
      console.error('Invalid device info!');
      return [];
    }

    const access = await CheckAccess(
      db,
      dataObj.device.device_id,
      dataObj.device.data.owner.id
    );
    if (!access.isValid) {
      console.log('Access denied! Invalid user.');
      return [];
    }
    //console.log(dataObj.device.data.owner.registeredTime, dataObj.endTime);
    if (dataObj.device.data.owner.registeredTime > dataObj.endTime * 1000) {
      console.log('Access denied!');
      return [];
    }
    if (dataObj.device.data.owner.registeredTime > dataObj.startTime * 1000) {
      dataObj.startTime = dataObj.device.data.owner.registeredTime;
    }

    const sensorDataRef = db
      .collection(
        utils.DEVICE_KEY +
          '/' +
          dataObj.device.device_id +
          '/' +
          utils.DAYLOG_KEY
      )
      .where(utils.UNIX_EPOCH_NAME, '>=', dataObj.startTime)
      .where(utils.UNIX_EPOCH_NAME, '<=', dataObj.endTime)
      .orderBy(utils.UNIX_EPOCH_NAME, 'desc');

    const sensorDoc = await sensorDataRef.get();

    return sensorDoc;
  } catch (error) {
    console.error(error);
    return [];
  }
}
